import React from "react"
import { Link } from "gatsby"

const UnaSesion = () => (

<div className="bg-gray-50">
    <div className="container py-6 lg:py-16">
        <h1 className="text-xl mb-4 leading-tight font-bold text-center text-gray-900 sm:mb-12 sm:text-2xl md:text-4xl lg:text-6xl">¿Prefieres una sola sesión?</h1>
        <div className="flex flex-wrap justify-center items-end">
            <section className="shadow-lg border-t-2 border-gray-50 mx-6 mt-6 w-3/4 bg-white rounded-lg sm:mx-6 md:w-5/12 lg:w-1/3 xl:w-1/4 ">
                <div className="px-6 pb-6">
                    <h1 className="text-xl mt-6  font-bold text-gray-900 md:text-2xl md:mt-6 lg:text-xl lg-mt-6">Discovery call</h1>
                    <h2 className="text-base mt-2 font-book text-gray-600 md:text-lg md:mt-3 lg:text-lg lg-mt-4">30 minutos</h2>
                    <h1 className="text-base mt-6 font-light text-gray-600 md:text-lg md:mt-8 lg:text-xl lg-mt-10"><span className="text-2xl font-bold text-gray-900 md:text-2xl lg:text-4xl">Gratis</span></h1>
                    <a 
                        className="mt-4 flex justify-center md:mt-6 lg:mt-8 w-full px-5 py-3 font-bold leading-snug border-2 border-green-800 text-base rounded-md text-green-800 bg-white hover:bg-green-800 hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                        href="https://koalendar.com/e/luzamira-discovery-call"
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="Lo quiero" >
                        ¡Lo quiero!
                    </a> 
                </div> 
            </section>
            <section className="shadow-lg mx-6 mt-6 w-3/4 bg-white rounded-b-lg sm:mx-6 md:w-5/12 lg:w-1/3 xl:w-1/4 ">
                
                <div className="px-6 pb-6">
                    <h1 className="text-xl mt-6  font-bold text-gray-900 md:text-2xl md:mt-6 lg:text-xl lg-mt-6">Consulta nutricional</h1>
                    <h2 className="text-base mt-2 font-book text-gray-600 md:text-lg md:mt-3 lg:text-lg lg-mt-4">60 minutos</h2>
                    <h1 className="text-base mt-6 font-light text-gray-600 md:text-lg md:mt-8 lg:text-xl lg-mt-10"><span className="text-2xl font-bold text-gray-900 md:text-2xl lg:text-4xl">$70</span> USD</h1>
                    <a 
                        className="mt-4 flex justify-center md:mt-6 lg:mt-8 w-full px-5 py-3 font-bold leading-snug border-2 border-green-800 text-base rounded-md text-green-800 bg-white hover:bg-green-800 hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                        href="https://koalendar.com/e/luzamira-consulta-nutricional"
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="Lo quiero" >
                        ¡Lo quiero!
                    </a>    
                </div> 
            </section>
            <section className="shadow-lg border-t-2 border-gray-50 mx-6 mt-6 w-3/4 bg-white rounded-lg sm:mx-6 md:w-5/12 lg:w-1/3 xl:w-1/4 ">
                <div className="px-6 pb-6">
                    <h1 className="text-xl mt-6  font-bold text-gray-900 md:text-2xl md:mt-6 lg:text-xl lg-mt-6">Empresarial</h1>
                    <h2 className="text-base mt-2 font-book text-gray-600 md:text-lg md:mt-3 lg:text-lg lg-mt-4">Organizaciones o grupos</h2>
                    <h1 className="text-2xl mt-6 md:mt-8 lg-mt-10 font-bold text-gray-900 md:text-2xl lg:text-4xl">Contacto</h1>
                    <Link 
                        to="/contacto"
                        alt="contacto"
                        className="mt-6 flex justify-center md:mt-6 lg:mt-8 w-full px-5 py-3 font-bold leading-snug border-2 border-green-800 text-base rounded-md text-green-800 bg-white hover:bg-green-800 hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                        >
                        Contáctame
                    </Link>
                </div> 
            </section>
        </div>
        <div className="text-center mt-12">
            <Link 
                    to="/servicios"
                    className="text-base font-medium border-b-2 border-green-600 pb-2 text-gray-900 hover:text-green-600 hover:border-green-200 transition duration-150 ease-in-out"
                >
                    Ver comparación completa
            </Link>
        </div>
    </div>
</div>
)

export default UnaSesion