import React from "react"
import { FaCheck } from "react-icons/fa"

const MasVerde = () => (

<div className="bg-gray-50">
    <div className="container py-6 lg:py-16">
        <h1 className="text-xl mb-6 leading-tight font-bold text-center text-gray-900 sm:text-2xl md:text-4xl lg:text-6xl">Estás a un paso de alcanzar tu bienestar</h1>
        <h2 className="text-base mb-4 leading-normal font-light text-center text-gray-900 sm:mb-12 sm:text-lg md:text-xl lg:text-2xl">Logra un objetivo sostenible a largo plazo y a la vez crea una relación sana con tu alimentación.</h2>
        <div className="flex flex-wrap justify-center items-center">
            <section className="shadow-lg mr-0 w-3/4 bg-white rounded-b-lg sm:mr-6 md:w-5/12 lg:w-1/3 xl:w-1/4 ">
                <div className="py-2 px-6 bg-green-200 rounded-t-lg text-center">
                    <p className="text-tiny font-book text-gray-900 md:text-base">Ahorras $60 USD </p>
                </div>
                <div className="px-6 pb-6">
                    <h1 className="text-xl mt-6  font-bold text-gray-900 md:text-2xl md:mt-6 lg:text-3xl lg-mt-6">Programa Más verde</h1>
                    <h2 className="text-base mt-2 font-book text-gray-600 md:text-lg md:mt-3 lg:text-xl lg-mt-4">6 meses</h2>
                    <h1 className="text-base mt-6 font-light text-gray-600 md:text-lg md:mt-8 lg:text-xl lg-mt-10"><span className="text-2xl font-bold text-gray-900 md:text-2xl lg:text-4xl">$500</span> USD</h1>
                    <a 
                        className="mt-4 flex justify-center md:mt-6 lg:mt-8 w-full px-5 py-3 font-bold  leading-snug border border-transparent text-base rounded-md text-white bg-green-800 hover:bg-green-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                        href="https://koalendar.com/e/luzamira-mas-verde"
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="Lo quiero" >
                        ¡Lo quiero!
                    </a> 
                </div> 
            </section>
            <div className="sm:ml-6 mr-0 w-3/4 md:w-5/12 lg:w-1/3 xl:w-1/4 ">
                <h1 className="mt-4 font-medium text-lg md:mt-0">El programa incluye:</h1>
                <div className="mt-6">
                    <ul className="grid grid-cols-6 grid-rows-8 grid-flow-row">
                        <li className="flex justify-center"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                        <li className="font-light text-base col-span-5">Consulta inicial.</li>
                        <li className="flex justify-center"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                        <li className="font-light text-base col-span-5">7 consultas de seguimiento.</li>
                        <li className="flex justify-center"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                        <li className="font-light text-base col-span-5">Evaluación de composición corporal.</li>
                        <li className="flex justify-center"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                        <li className="font-light text-base col-span-5">Revisión de exámenes bioquímicos.</li>
                        <li className="flex justify-center"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                        <li className="font-light text-base col-span-5">PDF con plan de alimentación, suplementación, recomendaciones y guía de supermercado.</li>
                        <li className="flex justify-center"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                        <li className="font-light text-base col-span-5">Cambios o ajustes en el plan de Alimentación.</li>
                        <li className="flex justify-center"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                        <li className="font-light text-base col-span-5">Recetario.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
)

export default MasVerde