import { Link, graphql} from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import MasVerde from "../components/servicios/MasVerde"
import UnaSesion from "../components/servicios/UnaSesion"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

import atreverse from "../images/atreverse.svg"
import metodo from "../images/metodo.svg"
import proceso from "../images/proceso.svg"
import bienestar from "../images/bienestar.svg"

import Button from "../components/Button"
import { FaChevronDown, FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import { AnchorLink } from "gatsby-plugin-anchor-links";



const IndexPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="Inicio" description="Nutricionista Dietista panameña especialista en patologías digestivas, nutrición oncológica, vegetarianos y veganos. Panamá, ciudad de Panamá." />

    <div className="bg-white">
      <div className="container py-8 lg:py-16">
        <div className="flex flex-wrap justify-around items-center">
          <div className="w-full md:w-1/2 xl:w-1/2 pb-8 md:pb-0">
            <h1 className="text-4xl leading-tight lg:leading-snug font-bold text-gray-900 lg:text-7xl">
              Nutrición basada en plantas
            </h1>
            <div className="my-6 font-light leading-normal text-lg md:text-base lg:text-2xl">
              Te ayudo a reconectar con tu cuerpo incorporando hábitos saludables según tu estilo de vida.
            </div>
            <div className="flex flex-wrap items-center justify-center md:justify-center">
              <a 
                  href="https://koalendar.com/e/luzamira-consulta-nutricional" 
                  className="m-2 md:m-4 xl:m-8" 
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <Button alt="Agenda una cita">Agenda una cita</Button>
              </a>
              <AnchorLink to="/#metodo" title="Mi metodo" className="">
                <div className="m-2 md:m-4 xl:m-8 flex flex-wrap w-full px-5 py-3 font-bold  leading-snug text-base md:text-sm lg:text-base text-green-800 hover:text-green-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  <span className="mr-2">Conoce mi método</span>
                  <FaChevronDown className="h-6 w-auto fill-current ml-2" />
                </div>
              </AnchorLink>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/2 px-0 sm:px-32 md:px-12">
            <Img
              fluid={data.Author.childImageSharp.fluid}
              alt="Luz Amira"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="metodo" className="bg-gray-50">
      <div className="container flex items-center justify-center">
        <div className="">
          <div className="px-4 md:px-16 lg:px-32">
            <div className="flex flex-wrap justify-center py-10 md:py-12 lg:py-16"> 
              <FaQuoteLeft className="h-6 md:h-8 lg:h-12 w-auto flex items-center fill-current ml-2 px-8"/> 
              <FaQuoteRight className="h-6 md:h-8 lg:h-12 w-auto flex items-center fill-current ml-2 px-8"/>
            </div>

            <h1 className="text-lg md:text-2xl lg:text-6xl leading-tight font-bold text-center text-gray-900 pb-6 md:pb-10 lg:pb-16">
              No es restringirnos, es llevar nuestro proceso un paso a la vez e incluir variedad.
            </h1>
            <div className="px-4 md:px-16 lg:px-32 text-tiny md:text-base lg:text-2xl font-light text-center leading-loose pb-6 md:pb-10 lg:pb-16">
            Te guiaré a consumir alimentos de una forma que no solo beneficie a tu cuerpo, si no que también sea sostenible con el medio ambiente.
            </div>
          </div>
          <div>
          <div className="flex justify-center mt-8 md:mt-16 lg:mt-32 mb-6 md:mb-8 lg:mb-12">
            <AnchorLink to="/#proceso" title="Mi proceso">
              <div className="text-green-900 hover:text-green-600">
                <span className="font-bold text-base text-center mr-2">Descubre cómo</span>
              </div>
              <div className="flex justify-center">
                <div className="text-green-800 hover:text-green-600 ">
                <FaChevronDown className="h-6 w-auto fill-current mt-6" />
                </div>
              </div> 
            </AnchorLink>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div id="proceso" className="bg-gray-900">
      <div className="container py-12 md:py-32 lg:py-48 flex items-center">
        <div className="flex flex-wrap justify-around items-center">
          <div className="w-full md:w-1/2 xl:w-1/2 pb-2 md:pb-0">
            <p className="text-tiny pb-4 leading-none font-bold text-white md:text-base lg:text-lg">
              Paso 1
            </p>
            <h1 className="text-xl leading-none font-bold text-white sm:text-2xl md:text-4xl lg:text-6xl">
            Atreverse
            </h1>
            <div className="text-tiny my-6 font-light text-white leading-normal md:text-base lg:text-lg">
            Al tomar la decisión de iniciar un cambio de hábitos, donde estos sean saludables y compasivos con tu estilo de vida, estaré disponible para brindarte apoyo y guía para que alcances tu bienestar.
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/2 md:px-12">
            <img src={atreverse} alt="Paso 1" />
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white">
      <div className="container py-12 md:py-32 lg:py-48 flex items-center">
        <div className="flex flex-wrap-reverse justify-around items-center">
          <div className="w-full md:w-1/2 xl:w-1/2 md:px-12">
            <img src={metodo} alt="Paso 2" />
          </div>
          <div className="w-full md:w-1/2 xl:w-1/2 pb-2 md:pb-0">
            <p className="text-tiny pb-4 leading-none font-bold text-gray-900 md:text-base lg:text-lg">
              Paso 2
            </p>
            <h1 className="text-xl leading-none font-bold text-gray-900 sm:text-2xl md:text-4xl lg:text-6xl">
            Encontrar tu método
            </h1>
            <div className="text-tiny my-6 font-light leading-normal md:text-base lg:text-lg">
            Al iniciar debo conocerte, desde tus patrones de hábitos, accesos, preferencias, perfiles bioquímicos, entre mucho más. Desde ahí poder brindarte las herramientas donde darás inicio a incluir metas especificas en tu alimentación en conjunto a llevar un mindset saludable, movimiento, descanso y un estilo de vida balanceado.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-gray-900">
      <div className="container py-12 md:py-32 lg:py-48 flex items-center">
        <div className="flex flex-wrap justify-around items-center">
          <div className="w-full md:w-1/2 xl:w-1/2 pb-2 md:pb-0">
            <p className="text-tiny pb-4 leading-none font-bold text-white md:text-base lg:text-lg">
              Paso 3
            </p>
            <h1 className="text-xl leading-none font-bold text-white sm:text-2xl md:text-4xl lg:text-6xl">
            Trabajar en tu proceso
            </h1>
            <div className="text-tiny my-6 font-light text-white leading-normal md:text-base lg:text-lg">
            Llegar al bienestar no es lineal, por eso es importante seguir aprendiendo, evaluando y mejorando un paso a la vez a lo largo del proceso. Resolveré tus dudas e inquietudes, mediremos tus progresos y realizaré los cambios que se alineen a ti.
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/2 md:px-12">
            <img src={proceso} alt="Paso 3" />
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white">
      <div className="container py-12 md:py-32 lg:py-48 flex items-center">
        <div className="flex flex-wrap-reverse justify-around items-center">
          <div className="w-full md:w-1/2 xl:w-1/2 md:px-12">
            <img src={bienestar} alt="Paso 4" />
          </div>
          <div className="w-full md:w-1/2 xl:w-1/2 pb-2 md:pb-0">
            <p className="text-tiny pb-4 leading-none font-bold text-gray-900 md:text-base lg:text-lg">
              Paso 4
            </p>
            <h1 className="text-xl leading-none font-bold text-gray-900 sm:text-2xl md:text-4xl lg:text-6xl">
            Alcanzar el bienestar
            </h1>
            <div className="text-tiny my-6 font-light leading-normal md:text-base lg:text-lg">
            Al finalizar el programa podrás alimentarte más intuitivamente y según lo que te beneficie. Te daré las herramientas que necesitas para que sigas tu progreso y mantengas un estilo de vida saludable, sostenible, balanceado y realista.
            </div>
          </div>
        </div>
      </div>
    </div>

    <MasVerde/>
    <UnaSesion/>

    <div className="bg-white">
      <div className="container py-12 lg:py-16">
      <h1 className="text-xl mb-4 leading-tight font-bold text-center text-gray-900 sm:mb-12 sm:text-2xl md:text-4xl lg:text-6xl">Sobre mi</h1>
        <div className="flex flex-wrap justify-around items-center">
          <Img
            fluid={data.About.childImageSharp.fluid}
            alt="Luz Amira"
            className="w-48 h-48 md:w-1/3 lg:w-1/4 md:h-auto rounded-lg mx-auto"
          />
          <div className="pt-6 md:pt-0 md:px-24 md:w-2/3 lg:w-3/4">
            <h1 className="text-lg leading-tight font-bold text-gray-900 text-center md:text-left sm:text-xl md:text-2xl">
              Luz Amira Caballero.
            </h1>
            <div className="my-6 text-base font-light leading-normal sm:text-lg md:text-lg">
            Soy Nutricionista Dietista egresada de la Universidad Interamericana de Panamá. Actualmente me dedico a la consulta online tanto de niños como de adultos. A su vez tengo esta plataforma para complementar en brindar recomendaciones, recetas e información con evidencia científica sobre alimentación saludable. 
            </div>
            <div className="flex justify-center md:justify-start">
              <Link 
                  to="/sobre-mi"
                  alt="Sobre mi"
                  className="flex justify-center px-5 py-3 font-bold leading-snug border-2 border-green-800 text-base rounded-md text-green-800 bg-white hover:bg-green-800 hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                  Conóceme
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Newsletter />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    Author: file(relativePath: { eq: "author.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    About: file(relativePath: { eq: "luz-amira.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, maxWidth: 400, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
